.App{
  background-color: white;
  min-height: 100vh;
}
.App-logo {
  width: 220px;
  height: 100px;
  margin-top: 5px;
}
header{
  display: flex;
  justify-content: center;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header-child {
  width: 780px;
  height: 585px;
  position: relative;
}
.App-header-child-btn{
  position: absolute;
  z-index: 7;
  margin-left: 43%;
  margin-top: 1%;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-color: #555555;
  border-radius: 15px;
  display: none;
}
@media (max-width: 950px) {
  .App-header-child {
    width: 640px;
    height: 480px;
    position: relative; 
  }
}
@media (max-width: 700px){
  .App-header-child{
    width: 420px;
    height: 315px;
  }
  .App-header-child-btn{
    margin-left: 35%;

  }
}
@media (max-width: 440px){
  .App-header-child{
    width: 290px;
    height: 217.5px;
    margin-top: 20px;
  }
  .App-logo {
    width: 150px;
    height: 70px;
  }
}

@media (max-device-width: 480px) {
  .App-header-child{
    width: 217.5px;
    height: 290px;
    margin-top: 10px;
  }
  .App-logo {
    width: 150px;
    height: 70px;
    margin-top: 10px;
  }
  .App-header-child-btn{
    display: block;
    margin-top: 2%;
    margin-left: 12%;
  }
}

@media (max-device-width: 680px) {
  .App-header-child{
    width: 315px;
    height: 420px;
    margin-top: 20px;
  }
  .App-logo {
    width: 150px;
    height: 70px;
    margin-top: 5px;
  } 
  .App-header-child-btn{
    margin-left: 29%;
  }
}